// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMapMarkerAlt,
//   faPhone,
//   faEnvelope,
//   faAngleDoubleRight,
// } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";
// import "./Contact.css";
// import { servicesData } from "../Serv/ServicesData";

// export const Contact = () => {
//   return (
//     <footer className="contact-us" id="contact">
//       <div className="contact-details">
//         <h2>Contact Us</h2>
//         <div className="leftcontact">
//           <p className="address">
//             <FontAwesomeIcon icon={faMapMarkerAlt} /> Office A707, Rahul
//             Downtown Mumbai Pune Bypass Rd Punavale ,Pimpri Chinchwad Pune
//             411033
//           </p>
//           <p className="phone">
//             <FontAwesomeIcon icon={faPhone} /> 7028393307
//           </p>
//           <p className="email">
//             <FontAwesomeIcon icon={faEnvelope} />{" "}
//             <a className="emailcontact" href="mailto:hr@Fusionxcloud.com">
//               hr@Fusionxcloud.com
//             </a>
//           </p>
//         </div>
//       </div>
//       <div className="services-section">
//         <h3>Our Services</h3>
//         <ul>
//           {servicesData.map((service) => (
//             <li key={service.id}>
//               <Link to={`/services/${service.id}`}>
//                 {" "}
//                 <FontAwesomeIcon
//                   icon={faAngleDoubleRight}
//                   className="service-icon"
//                 />{" "}
//                 {service.title}
//               </Link>
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div className="contact-image">
//         <img src="/Assets/contact1.svg" alt="Contact Us" />
//       </div>
//     </footer>
//   );
// };
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./Contact.css";
import { servicesData } from "../Serv/ServicesData";

export const Contact = () => {
  return (
    <footer className="contact-us" id="contact">
      <div className="logo-section">
        <img
          src="/Assets/logomain.png"
          alt="Company Logo"
          className="footerlogo"
        />
        <h2 className="company-name">
          {/* Fusion<span className="spantwo">X</span>cloud */}
          On Intelligence, Integrations, Cloud And Infrastructure Technologies!
        </h2>
      </div>
      <div className="services-section">
        <h2>Our Services</h2>
        <ul>
          {servicesData.map((service) => (
            <li key={service.id}>
              <FontAwesomeIcon icon={faAngleDoubleRight} />{" "}
              <Link to={`/services/${service.id}`}>
                {" "}
                <FontAwesomeIcon
                  icon={faAngleDoubleRight}
                  className="service-icon"
                />{" "}
                {service.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="contact-details">
        <h2>Contact Us</h2>
        <div className="leftcontact">
          <p className="address">
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Office 208 City Avenue Wakad, Pune 411057.
          </p>
          <p className="phone">
            <FontAwesomeIcon icon={faPhone} />+91 7028393307
          </p>
          <p className="email">
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a className="emailcontact" href="mailto:info@fusionxcloud.com">
              info@fusionxcloud.com
            </a>
          </p>
        </div>
      </div>

      <div className="contact-image">
        <img src="/Assets/contact1.svg" alt="Contact Us" />
      </div>
      <div className="footer-bottom">
        <hr className="footer-line" />
        <div className="footer-text">
          <p className="copyright">
            Copyright © FusionXcloud. All Rights Reserved.
          </p>
          <div className="policy-links">
            <Link to="/terms" className="policy-link">
              Terms & Conditions
            </Link>
            <span className="dividerspan">  ||  </span>
            <Link to="/privacy" className="policy-link">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
